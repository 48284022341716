/* General CSS classes */

.becky-main-title {
    @apply text-4xl md:text-5xl lg:text-6xl; /* Font Size 2xl:text-8xl */
    @apply text-center lg:text-left; /* Text Style */
    @apply  text-bbw-brown font-bold; /* Font Style */
}

.becky-sub-title {
    @apply text-3xl md:text-4xl lg:text-5xl; /* Font Size 2xl:text-7xl */
    @apply text-center; /* Text Style */
    @apply  text-bbw-brown font-bold; /* Font Style */
}

.becky-section-description {
    @apply md:text-sm lg:text-lg xl:text-xl; /* Font Size 2xl:text-3xl */
    @apply text-center lg:text-left; /* Text Style */
    @apply text-bbw-gray; /* Font Style */
}


.becky-text-x-small {
    @apply text-sm;
}

.becky-text-small {
    @apply text-base;
}

.becky-text-small-center {
    @apply becky-text-small text-center;
}

.becky-text-sub-heading {
    @apply text-4xl text-bbw-brown font-bold;
}

.becky-text-heading-dark {
    @apply font-bold becky-main-title;
    @apply text-center md:text-left text-bbw-dark-brown;
}

.becky-text-heading {
    @apply lg:text-6xl text-4xl text-bbw-brown font-bold text-center;
}

.becky-card-layout {
    @apply p-6 shadow-custom;
    border-radius: 20px;
}

.becky-border-bottom {
    @apply border-b border-bbw-light-gray;
    position: absolute;
    left: 35%;
    border-bottom-width: thick;
    bottom: 0;
    border-radius: 10px;
    width: 30%;
}

.becky-flex-center-lg {
    @apply lg:flex lg:justify-center lg:items-center;
}

.becky-flex-center-md {
    @apply md:flex md:justify-center md:items-center;
}

.becky-flex-center-vertical-lg {
    @apply lg:flex lg:items-center;
}

.becky-flex-center-horizontal {
    @apply flex justify-center;
}

.beck-button-sm {
    @apply becky-button lg:hidden;
}

.becky-button {
    @apply becky-flex-center-lg bg-bbw-brown pb-2 pt-2 pl-4 pr-4
    rounded-lg rounded-br-none text-bbw-white becky-text-small;
    /*@apply 2xl:text-3xl 2xl:pb-4 2xl:pt-4 2xl:pl-8 2xl:pr-8;*/
}

.becky-button-outlined {
    @apply becky-flex-center-lg pb-2 pt-2 pl-6 pr-6
    border-bbw-dark-brown border-2 rounded-lg rounded-br-none becky-text-small;
}

.border-right::after {
    @apply lg:border-r-2 lg:border-bbw-brown border-none ml-4;
    content : "";
    height  : 50%;
}

/* UI CSS classes */
.becky-app {
    @apply min-h-screen bg-bbw-white box-border;
}

/* Header CSS*/
.becky-app__header {
    @apply lg:ml-10 lg:mr-10 lg:mt-2.5 lg:mb-2.5 m-5 flex justify-between items-center;
 }

.becky-app__header__logo {
    @apply grow-0 becky-flex-center-vertical-lg cursor-pointer;
}

.becky-app__header__logo::after {
    @apply lg:border-r-2 lg:border-bbw-blue lg:rounded;
    content : "";
    height  : 50%;
}

.becky-app__header__logo > .becky-app__header__logo--large {
    @apply w-[200px] max-w-[200px];
}

.becky-app__header__logo > .becky-app__header__logo--small {
    @apply w-[40px] max-w-[60px];
}

.becky-app__header__nav {
    @apply hidden lg:grow lg:ml-4 becky-flex-center-vertical-lg lg:justify-items-start;
}

.becky-app__header__nav > span {
    @apply grow-0 p-4 text-bbw-brown becky-text-small cursor-pointer;
    @apply 2xl:text-lg;
    /*@apply 2xl:text-2xl*/

}

.becky-app__header__community {
    @apply grow-0 becky-flex-center-vertical-lg hidden;
}

.becky-app__header__book-now_sm {
    @apply grow-0 flex justify-center becky-button;
}

.becky-app__header__community > button {
    @apply becky-button;
}

/* Location */
/* Temp CSS */

.becky-app__location > select {
    @apply p-1 rounded-md text-bbw-brown w-[35%] mr-8;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}

/* Hero CSS*/
.becky-app__hero {
    @apply ml-10 mr-10 mt-2.5 mb-2.5;
    @apply flex flex-wrap flex-col-reverse lg:flex-row;
    @apply lg:h-[85vh] xl:h-[90vh];
}

.becky-app__hero__text {
    @apply lg:basis-6/12 flex justify-center flex-wrap lg:items-center;
}

.becky-app__hero__image {
    @apply lg:basis-6/12 becky-flex-center-vertical-lg flex justify-center lg:justify-end;
}

.becky-app__hero__image > img {
    @apply w-[400px] lg:w-[550px];
}

.becky-app__hero__services {
    @apply basis-full becky-flex-center-lg p-4 hidden;
}

.becky-app__hero__services__item {
    @apply text-bbw-brown becky-text-small font-semibold pr-4;
}

/* Intro CSS */
.becky-app__intro {
    @apply ml-10 mr-10 mt-2.5 mb-2.5 flex text-center flex-col md:text-left flex-wrap;
}

.becky-app__intro__heading {
    @apply becky-flex-center-lg p-4 text-center md:text-left;
 }

.becky-app__intro__subtext {
    @apply becky-flex-center-lg p-4 max-w-5xl;
}

.becky-app__intro__image {
    @apply becky-flex-center-lg becky-flex-center-md h-auto w-full hidden;
}

.becky-app__intro__image-mobile {
    @apply flex justify-center h-auto w-full md:hidden lg:hidden;
}

.becky-app__intro__image > img {
    @apply p-2 lg:w-[800px] xl:w-[950px] w-full;
}

.becky-app__intro__image-mobile > img {
    @apply p-2 sm:w-[400px] w-[300px];
}

/* Banner CSS */

.becky-app__banner_wrapper {
    @apply bg-bbw-amber;
}

.becky-app__banner {
    @apply mt-3 mb-3 flex relative p-8 text-center md:text-left lg:w-[90%] max-w-[1024px] m-auto;
}

.becky-app__banner__offer {
    @apply becky-flex-center-lg md:w-[500px];
    flex-direction: column;
    align-items: self-start;
    height: inherit;
}

.becky-app__banner__image {
    @apply absolute right-0 bottom-0 lg:right-[8%] hidden md:block max-w-[600px];
    /*height: inherit;*/
}

.becky-app__banner__image > img {
    /*height: inherit;*/
}

.becky-banner-text {
    @apply becky-text-small text-bbw-brown mb-8;
}

/* Services CSS */
.becky-app__services {
    @apply ml-2 mr-2 md:ml-10 md:mr-10 mt-5 mb-2.5 flex-wrap;
    /*min-height: 100vh;*/
}

.becky-app__services__heading {
    @apply becky-flex-center-lg p-4 m-auto;
}

.becky-app__services__subtext {
    @apply p-4;
    @apply flex justify-center items-center;
    @apply becky-section-description max-w-5xl m-auto;

}

.becky-app__services__list {
    @apply mt-4 mb-4;
}

.becky-app__services__detail__header {
    @apply relative mb-4 w-[100%];
}

.becky-app__services__detail {
    @apply p-4 flex-col ;
    @apply flex justify-center items-center;
}

.becky-app__services__detail__description {
    @apply w-[100%] md:w-[600px];
}

/* Slick CSS to be refactored */
.slick-slider {
    display: flex;
}

.slick-list {
    @apply pl-4 pr-4;
    @apply flex items-center;
    overflow: hidden;
    height: auto;
}

.slick-list-child {
    height: 100%;
}

.slick-track {
    display: flex;
    align-items: center;
    transform: translate(10px, 10px);
}

.slick-track > * {
    /*margin: 0 1.4%;*/
    margin: 0;
    @apply mx-[1.4%] lg:mr-[1.2%] xl:mr-[1.4%];
    /*@apply mx-[2%] md:mx-[118px] lg:mx-[70px] xl:mx-[118px] 2xl:mx-[260px];*/
}

.slick-center > div {
    height: 100%;
}

.slick-active {
    transform: scale(0.5);
    transition: all 0.5s ease;
}

.slick-center {
    /*@apply scale-[2.5] md:scale-[1.3] lg:scale-[2.1];*/
    transform: scale(1);
    transition: all 0.5s ease;
}

.slick-prev {
    color: transparent;
}

.slick-next {
    color: transparent;
}

.slick-prev:before {
    @apply text-bbw-gray;
    content: "<";
    font-size: 30px;
}

.slick-next:before {
    @apply text-bbw-gray;
    content: ">";
    font-size: 30px;
}
/* Gallery CSS */
.becky-app__gallery {
    @apply mt-5 mb-2.5 flex-wrap;
}

.becky-app__gallery__header {
    @apply becky-flex-center-lg p-4 mb-5 becky-sub-title;
}

.becky-app__gallery__photos {
    /*@apply lg:min-h-[70vh];*/
    /*max-height: 70vh;*/
}

/* Testimonial CSS */
.becky-app__testimonials {
    @apply md:ml-10 md:mr-10 mt-5 mb-2.5 flex-wrap;
}

.becky-app__testimonials__header {
    @apply becky-flex-center-lg p-4 becky-sub-title;
}

.becky-app__testimonials__cards {
    @apply p-4 flex-wrap flex justify-center;
    min-height: 70vh;
}

.becky-app__testimonials__card {
    @apply m-6 md:basis-2/5 lg:basis-1/4;
    min-width: 25%;

}

/* Footer CSS */
.becky-app__footer {
    @apply ml-10 mr-10 mt-5 mb-2.5 becky-flex-center-vertical-lg;
}

.becky-app__footer__left {
    @apply becky-flex-center-horizontal flex-col;
}

.becky-app__footer__address {
    @apply p-2 flex-grow becky-flex-center-horizontal text-bbw-gray flex-col;
    min-height: 10vh;
    width: 50%;
}
