/* Can be the common classes */
.becky-flex-center {
    @apply flex justify-center items-center;
}

.becky-text-small {
    @apply text-base;
}

:root {
    --section-height-md: 70vh;
}

/* Join Us CSS*/
.becky-app__join-us {
    @apply min-h-screen becky-flex-center flex-col pt-[20vh];
    /* TODO: Find the right path of the image. */
    /* TODO: Reduce the size of the image. */
    @apply w-full bg-[url('./hero-2.png')] h-screen bg-center bg-cover;
}

.becky-app__join__heading {
    /* TODO: Find Tailwind alternative. */
    text-shadow: 2px 2px 4px rgba(36, 36, 36, 0.25);
    @apply text-center text-bbw-white font-bold becky-flex-center leading-none px-2;
    @apply text-[2rem] sm:text-[4rem] md:text-[6rem] lg:text-[8rem] 2xl:text-[10rem];
}

.becky-app__join__action {
    @apply becky-flex-center bg-bbw-amber py-2 pl-4 pr-4 mt-10
    rounded-lg text-bbw-white text-xl;
}

.becky-app__join__subText {
    @apply text-bbw-white font-bold text-2xl mt-10 text-center px-2;
}

/* Qualities CSS */
.becky-app__qualities {
    @apply ml-10 mr-10 mt-2.5 mb-2.5;
}

.becky-app__qualities__heading {
    @apply becky-flex-center p-4 text-bbw-brown text-3xl font-bold text-center;
}

.becky-app__qualities__cards {
    @apply flex flex-wrap lg:w-[90%] justify-around m-auto max-w-screen-2xl p-2;
}

.becky-app-p-card-container {
    @apply flex justify-center basis-1/3;
}

/* Stories CSS */
.becky-app__stories {
    @apply mt-2.5 mb-2.5 flex flex-col sm:flex-row min-h-[--section-height-md] h-[80vh] ;
}

.becky-app__stories-wallpaper {
    @apply w-full bg-[url('./assets/story.webp')] bg-center bg-cover basis-1/2 lg:basis-3/5 min-h-[40vh] flex justify-center items-center;
}

.becky-app__stories-wallpaper > span {
    @apply sm:hidden text-[40px] text-bbw-white font-bold leading-none p-8;
}

.becky-app__stories-body {
    @apply relative flex flex-col justify-end basis-1/2 lg:basis-2/5 bg-bbw-amber;
}

.becky-app__stories-body > span {
    @apply text-[60px] xl:text-[80px] w-[350px] lg:w-[500px] text-bbw-white font-bold leading-none hidden sm:block;
    @apply absolute top-[-60%] sm:top-[40px] sm:left-[-80px];
}

.becky-app__stories-content {
    @apply w-[80%] m-auto sm:m-0 sm:ml-8 py-8 lg:py-14;
}

.becky-app__stories-content-description {
    @apply text-bbw-black mb-2;
}

.becky-app__stories-content-action {
    @apply bg-bbw-white px-4 py-2 rounded-lg text-lg mt-2;
}

/* Journey CSS */
.becky-app__journey {
    @apply lg:h-screen flex-wrap becky-flex-center p-2;
}

.becky-app__journey > img {
    @apply w-auto lg:max-h-[90vh];
}

/* Community CSS */
.becky-app__community {
    @apply mt-2.5 mb-2.5 flex flex-col-reverse sm:flex-row min-h-[--section-height-md] h-[80vh] ;
}

.becky-app__community-wallpaper {
    @apply w-full bg-[url('./assets/base.webp')] bg-center bg-cover basis-1/2 lg:basis-3/5 flex justify-center items-center;
}

.becky-app__community-wallpaper > span {
    @apply sm:hidden text-[40px] text-bbw-white font-bold leading-none p-8;
}

.becky-app__community-body {
    @apply flex flex-col justify-end items-end basis-1/2 lg:basis-2/5 bg-bbw-amber;
    position: relative;
}

.becky-app__community-body > span {
    @apply text-[60px] xl:text-[80px] w-[350px] lg:w-[500px] text-bbw-white font-bold leading-none hidden sm:block;
    @apply absolute top-[40px] right-[-250px];
}

.becky-app__community-content {
    @apply w-[80%] m-auto sm:m-0 sm:ml-8 py-8 lg:py-14;
}

.becky-app__community-content-description {
    @apply text-bbw-black mb-2;
}

.becky-app__community-content-action {
    @apply bg-bbw-white px-4 py-2 rounded-lg text-lg mt-2;
}

/* Potential Card */
.becky-app-p-card {
    @apply flex flex-col items-center text-center w-[300px] p-2 m-4 mb-6;
}

.becky-app-p-card__icon {
    @apply mb-2;
}

.becky-app-p-card__title {
 @apply mb-2 text-bbw-brown font-bold;
}

.becky-app-p-card__description {
    @apply text-bbw-gray;

}